/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    strong: "strong",
    h2: "h2",
    div: "div",
    ul: "ul",
    li: "li",
    h3: "h3"
  }, _provideComponents(), props.components), {TableOfContents, MapInfo, TwoColCta, LandingPageCta, ThreeColCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!MapInfo) _missingMdxReference("MapInfo", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  if (!ThreeColCta) _missingMdxReference("ThreeColCta", true);
  if (!TwoColCta) _missingMdxReference("TwoColCta", true);
  return React.createElement(React.Fragment, null, React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Having conversations in loud environments can be challenging for anyone, and especially people with hearing loss. Speech sounds appear muffled and soft. Many people struggle to hear what others say to them while communicating in crowded areas, such as restaurants, parties, and public transportation."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Filtering out background noise is complicated when experiencing hearing loss. Although ", React.createElement(_components.a, {
    href: "/hearing-aids/",
    className: "c-md-a"
  }, "hearing aids"), " can solve the problem, everyday noises that never bothered you before can sometimes be overwhelming."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Fortunately, multiple types of ", React.createElement(_components.strong, null, "hearing aids with noise cancellation"), " features allow wearers to reduce background noise. At ", React.createElement(_components.a, {
    href: "/",
    className: "c-md-a"
  }, "hear.com"), ", we offer a variety of noise cancelling hearing aids to suit your unique needs. Take our quiz to find out if you qualify for a risk-free hearing aid trial."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "why-is-background-noise-a-problem",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#why-is-background-noise-a-problem",
    "aria-label": "why is background noise a problem permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Why Is Background Noise a Problem?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Background noise causes a range of problems for people with hearing loss. Spending time in noisy environments can be frustrating and stressful. Human ears receive and process surrounding noises coming from all different directions. It can be distracting and challenging to communicate when environmental noises, such as traffic, loud music, and other sounds are around you."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "hearing-aids-technology",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#hearing-aids-technology",
    "aria-label": "hearing aids technology permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Hearing Aids Technology"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/hearing-aids/technology/",
    className: "c-md-a"
  }, "Advanced hearing aid technology"), " has made hearing aids more effective in a wide variety of environments and at different noise levels. Although all hearing aids process sound, newer brands on the market have a range of features that can decrease the volume of surrounding noises and sounds. While they might not cancel background noise entirely, they can make it easier to hear someone talking to you despite environmental sounds."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "An automatic system increases the volume of soft speech and lowers the volume of loud noises. This provides the wearer’s desired outcome in multiple types of environments without the need to change the settings on the noise cancelling hearing aid manually."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Some newer hearing aids also come with artificial intelligence. This allows the device to process sound by accessing a deep neural network. The hearing aids can automatically switch to predetermined settings and preferences upon detecting specific sound environments."), "\n", React.createElement(MapInfo), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "how-background-noise-reduction-in-hearing-aids-work",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#how-background-noise-reduction-in-hearing-aids-work",
    "aria-label": "how background noise reduction in hearing aids work permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "How Background Noise Reduction in Hearing Aids Work"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Modulation-based systems analyze speech and noise levels in any environment. Mechanisms in these systems can differentiate between the signals speech and noise give off and alter the output."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The system instantly decreases the volume of loud noises, so the wearer understands speech better when it detects speech and loud noise signals. When the system detects loud noises but no speech signals, it automatically decreases the volume so the wearer can enjoy a more comfortable hearing level."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Noise reduction systems adjust sound at different frequency bands. Anyone wearing hearing aids with background noise reduction can converse with others more easily while the system automatically turns up speech frequencies and turns down frequencies containing loud noises."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Technology continues to change and improve. Anyone with hearing loss can now benefit from a more refined noise reduction system. Newer hearing aids with background noise reduction contain multiple channels or bands that function depending on the frequencies of noise and speech it processes. The noise reduction system can work within each separate channel for targeted noise reduction responses."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "When you have hearing loss, it’s crucial to choose a hearing aid with features such as:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Digital noise reduction – The digital noise reduction system analyzes and detects unwanted noise. It reduces the level of unwanted background or environmental noise, so listening is more comfortable."), "\n"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Impulse noise reduction – Impulse noise reduction systems improve the wearer’s comfort while listening to the sounds around them. The system detects temporary but loud noises, such as rattling dishes or typing on a keyboard, and instantly softens them."), "\n"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Wind noise reduction – Wind noise reduction is beneficial for anyone who likes spending time outdoors. Whether golfing, boating, or walking outside, this system detects wind and reduces or avoids the amplification of the noise."), "\n"), "\n"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "why-hearcom",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#why-hearcom",
    "aria-label": "why hearcom permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Why Hear.com?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "hear.com believes in quality. We offer our customers some of the most advanced hearing aids on the market today. You can choose from a range of products that contain world-leading ", React.createElement(_components.a, {
    href: "/hearing-aids/german/",
    className: "c-md-a"
  }, "German technology"), ". They can give you your life back so you can return to your routine without limitations."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "We handpicked the top 2% of hearing professionals in the country to work with, so our customers receive top-notch care for hearing loss. Everyone’s condition differs and requires personalized treatment. We will customize our services to match your needs for results that enhance your hearing regardless of your budget or level of hearing impairment."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "When it comes to hearing aids and background noise, you should trust us to provide a product suitable for your needs."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "best-background-noise-cancellation-hearing-aid-devices",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#best-background-noise-cancellation-hearing-aid-devices",
    "aria-label": "best background noise cancellation hearing aid devices permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Best Background Noise Cancellation Hearing Aid Devices"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you want to maintain an active lifestyle, you should ask hear.com about our unique hearing aids below:"), "\n", React.createElement(TwoColCta, {
    imgSrc1: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/go-ix-hearing-aids.png",
    copy1: "Horizon Go IX",
    url1: "/hearing-aids/horizon/go-ix/",
    imgSrc2: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/mini-ix-hearing-aids.png",
    copy2: "Horizon Mini IX",
    url2: "/hearing-aids/horizon/mini-ix/"
  }), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Our ", React.createElement(_components.a, {
    href: "/hearing-aids/horizon/",
    className: "c-md-a"
  }, "Horizon hearing aids"), " offer a new hearing experience with extra features for people on the go. An IP68 rating means each device is sweat, dirt, moisture, and dust resistant."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/hearing-aids/technology/bluetooth/",
    className: "c-md-a"
  }, "Bluetooth technology"), " allows you to make phone calls, listen to music, and stream your favorite TV shows directly to your hearing aids. You can activate the Speech Focus feature on your smartphone. Just tap the app so you can focus on your conversations despite the challenging listening environment. Choose the Relax Mode when you want to unwind and increase mindfulness with the sounds of the sea."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The nearly invisible award-winning design is comfortable, so you can wear them all day without pain or interruption to your hearing. You also don’t have to worry about carrying around batteries to recharge your hearing aids, and can use the portable charging case on the way to your destination."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "contact-an-experienced-audiologist-to-learn-more-about-our-noise-cancelling-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#contact-an-experienced-audiologist-to-learn-more-about-our-noise-cancelling-hearing-aids",
    "aria-label": "contact an experienced audiologist to learn more about our noise cancelling hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Contact an Experienced Audiologist to Learn More About Our Noise Cancelling Hearing Aids"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Complete our questionnaire to learn whether you qualify for our no-risk trial. For 45 days, you can use our hearing aids with noise cancellation technology and determine if they’re right for you."), "\n", React.createElement(LandingPageCta, {
    copy: "Start No-risk Trial",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "resources",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#resources",
    "aria-label": "resources permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Resources"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Learn everything you need to know about hearing aids and hearing loss."), "\n", React.createElement(ThreeColCta, {
    ImgSrc1: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/women-with-tablet.jpg",
    ContentTitle1: "Hearing Test",
    ContentCopy1: "Check how is your hearing in less than 5 minutes.",
    Url1: "/hearing-test/",
    ImgSrc2: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/hearing-aids-behind-ear-blur.jpg",
    ContentTitle2: "Value & Price",
    ContentCopy2: "Are hearing aids worth the cost? We asked an expert.",
    Url2: "/hearing-aids/prices/",
    ImgSrc3: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/go-ax-grey-blur.jpg",
    ContentTitle3: "Hearing Aids",
    ContentCopy3: "Connect you to the world around you with our wide range of devices.",
    Url3: "/hearing-aids/"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
